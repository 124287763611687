.a {
    height: 110vh;
    display: flex;
    align-items: center;
    margin-left: 39px;
    margin-right: 39px;

  }

  .a-left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
  }
  
  .a-right {
    flex: 1;
    flex: 1;
    margin-right:100px;
  }
  
  .a-card.bg {
    position: absolute;
    top: 50px;
    left: 50px;
    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  
  .a-card {
    width: 60%;
    height: 70vh;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
  }
  
  .a-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .figma {
    width: 72px;
    height: 68px;
    
  }

  .firebase {
    width: 67px;
    height: 67px;

  }


  .woo {
    width: 86px;
    height: 86px;

  }

  .react {
    width: 70px;
    height: 60px;

  }

  .wordpress {
    width: 69px;
    height: 69px;
  
  }
  
  .a-title {
    font-size: 50px;
    width: 100%;
    
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .a-title2 {font-size: 30px;
    color: white;
  }

  .a-sub {
    margin: 20px 0px;
    color: var(--color-text);
    font-size: 20px;
    font-weight: 500;
  }
  
  .a-desc{
      font-weight: 300;
      color: var(--color-text);
  }
  
  .a-award{
      margin-top: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
  
  .a-award-texts{
      width: 70%;
  }
  
  .a-award-title{
      font-weight: bold;
      color: #555;
      margin-bottom: 10px;
  }

  
  
  @media screen and (max-width:480px) {
      .a{
        flex-direction: column;
        text-align: center;
        margin-top: 20px;
      }
  
      .a-left {
        width: 100%;
      }
  
      .a-card {
        height: 40vh;
      }
  
      .a-card.bg, .a-award {
        display: none;
      }
  
      .a-right{
        padding: 20px;
        margin-right:0px;
      }

      .a-title {
        font-size: 30px;
      }
 
  }