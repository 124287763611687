.c {
    height: 100vh;
    position: relative;
    margin-left: 40px;
    margin-right: 40px;
  }
  
  .c-bg {
    width: 20px;
    height: 100%;
    background-color:#FF4820;
    position: absolute;
  }
  
  .c-wrapper {
    padding: 50px;
    display: flex;
  }
  
  .c-left {
    flex: 1;
  }
  
  ::placeholder {
    color:#042c54;
  }

  .c-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: center;
  }
  
  .c-title {
    font-size: 50px;
    width: 100%;
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
  }
  
  .c-info-item {
    display: flex;
    align-items: center;
    margin: 50px 0px;
    font-weight: 300;
    width: 70%;
    color: var(--color-text)
  }
  
  .c-icon {
    width: 30px;
    height: 30px;
    margin-right: 20px;
    color: var(--color-text)
  }
  
  .c-desc {
    font-weight: 200;
    margin-top: 18px;
    color: var(--color-text)
    
  }
  
  form {
    margin-top: 20px;
    
    
  }
  
  input {
    width: 60%;
    height: 50px;
    border: none;
    border-bottom: 1px solid black;
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px;
    background-color: #81AFDD;
    border-radius: 5px;
    color: #031B34;
    
  }
  
  textarea {
    width: 100%;
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px;
    background-color: #81AFDD;
    border-radius: 5px;
    color: #031B34;
  }
  
  button {
    border: none;
    padding: 15px;
    background-color: #FF4820;
    color: white;
    font-weight: 500;
    cursor: pointer;
    border-radius: 5px;
  }

  
  @media screen and (max-width: 480px) {
    .c {
      margin-top: 30px;
      margin-left: 0;
      margin-right:0;
    }

    .c-wrapper {
      flex-direction: column;
      padding: 0px 50px;
    }
  
    .c-title {
      font-size: 30px;
    }
  
    .c-info-item {
      margin: 20px 0px;
      width: 100%;
    }
  
    .c-desc {
      display: none;
    }
  
    form {
      margin-top: 0px;
      display: flex;
      flex-wrap: wrap;
    }
  
    input {
      width: 35%;
      height: 40px;
      margin: 10px;
      margin-left: 0;
    }
  
    button {
      margin-top: 10px;
    }
  }