section {
    display: flex;
    justify-content: space-between;
}

.portfolio {
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.pl-texts {
    width: 100%;
  }
  
  .pl-title {

    width: 100%;
    font-size: 50px;

    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .pl-desc {
    margin: 20px 0px;
    color: var(--color-text);

  }

a {
    color: red;
    transition: green;
}

a:hover {
    color: white;
}

.btn {
    width:max-content;
    display: inline-block;
    color: #FF4820;
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid;

}

.btn:hover {
    background: #FF4820;
    color: #042c54;
    border-color: transparent;
}

.btn-primary {
    background:  #FF4820;
    color: white;
    border-color: transparent;
}

.btn-primary:hover {
    color: white;
    background: #861900;
}

.container {
    width: 75%;
    margin: 0 auto;
}

.section {
    margin-top: 8rem;

}

.portfolio__container {
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    
}

.portfolio__item {
    background: #042c54;
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;

}

.portfolio__item:hover {
    background: transparent;
    border-color: #042c54;
}

.imgz {
    display: block;
    width: 100%;
    object-fit: cover;
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item h3 {
    margin: 1.2rem 0 2rem;
    display: flex;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

h3 {
    color: white;
    margin: 1rem 0 1rem;
    display: flex;
}

.card {
    background: #042c54;
    box-shadow: 0 0 15px;
    height: max-content;
    padding: 1.1rem;
    border-radius: 2rem;
    border: 1px solid transparent;
}

.card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1.5rem;
    overflow: hidden;
}

.card-body {
    padding: 10px 5px;
    text-align:justify;
}

.card-body p {
    color: #81AFDD;
    font-weight: 300;
}

.contain {
    gap: 20px;
    display:flex;
    align-items: flex-start;
    margin: 1.2rem 0 0rem;
    display: flex;

}

@media screen and (max-width: 1024px){
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

@media screen and (max-width:600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }


}

@media screen and (max-width: 480px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    .pl-title {
        font-size: 30px;
        margin-top: 220px;
    }

    .container {
        width: 85%;
    }

    .card-body {
        text-align:left;
    }
    
  
}

