
.i {
    display: flex;
    height: 100vh;
    margin-left: 40px;
    margin-right: 0px;
  }
  
  .i-left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  
  .i-right {
    flex: 1;
    position: relative;
    align-items:center;
    justify-content: center;
    z-index:1;

  }
  
  
  .i-left-wrapper {
    padding: 50px;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .i-intro {
    font-size: 30px;
    font-weight: 300;
    color: var(--color-text);
  }
  
  .i-desc {
    color: var(--color-text);
  }

  .i-name {
    font-size: 60px;
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width:100%;

  }
  
  .i-title {
    height: 50px;
    overflow: hidden;
  }
  
  .i-title-wrapper {
    height: 100%;
    animation: move 6s ease-in-out infinite alternate;
  }
  
  
  /* VVV Everything you need VVV */
  
  #scroll-down-animation {
    position: absolute;
    top: 88%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 5;
  }
  
  h2 {
    color: var(--color-text);
    font-family: 'Roboto', 'Arial', sans-serif;
    font-weight: 200;
    font-size: 16px;
  }
  
  .mouse {
    margin: 0 auto;
    display: block;
    border-radius: 50px;
    border: 2px solid var(--color-text);
    height: 50px;
    width: 26px;
    position: relative;
  }
  
  .move2 {
    position: absolute;
    background-color: var(--color-text);
    height: 10px;
    width: 10px;
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%);
    animation: move2 2s linear infinite;
  }
  
  @keyframes move2 {
    0% {
      transform: translate(-50%,1px);
      opacity: 0;
    }
    50% {
      transform: translate(-50%,20px);
      opacity: 1;
    }
    100% {
      transform: translate(-50%,32px);
      opacity: 0;
    }
  }

  @keyframes move {
    25% {
      transform: translateY(-50px);
    }
    50% {
      transform: translateY(-100px);
    }
    75% {
      transform: translateY(-150px);
    }
    100% {
      transform: translateY(-200px);
    }
  }
  

  .i-title-item {
    height: 50px;
    font-size: 30px;
    font-weight: bold;
    color: white;
    display: flex;
    align-items: center;
  }
  
  .i-right {
    flex: 1;
    position: relative;
    align-items:center;
    justify-content:center;
    z-index:3;
  }
  
  .i-img {
    width: 62%;
    height: 62%;
    object-fit: cover;
    position: absolute;
    margin-top:140px;
    margin-left:120px;
  }
  
  
  .i-bg {
    clip-path: polygon(100% 0%, 100% 51%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
    background-color: var(--color-blog);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    opacity:10%;
  }
  
  @media screen and (max-width: 480px) {
    .i {
      flex-direction: column;
      margin-left: 0;
      margin-right:0;
    }
  
    .i-left-wrapper {
    
      padding: 10px;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 100%;
    }
    
  
    .i-desc{
      display: none;
    }
    .i-img {

      margin-top:0px;
      margin-left:40px;
    }
   
    .i-title-item {
      align-items: center;
      justify-content: center;

    }

    .i-name {
      font-size: 25px;
      display: none;
    }
    
    .i-left {
      flex: 0.8;
    }

    .i-title{
      margin-top: 20px;
      
    }
    

    #scroll-down-animation {
      display:none;
    }

  }